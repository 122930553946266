<template>
  <transition name="fade" mode="out-in">
    <div class="change-password" :class="conditionalPageStyles">
      <h3 v-if="currentPasswordExpired">Change Expired Password</h3>

      <b-form @submit="changePassword" v-on:submit.prevent>
        <div class="invalid-feedback d-block" v-if="formError()">
          {{formErrorMessage()}}
        </div>

        <b-form-group label-for="currentPassword">
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text"><i class="icon icon-lock"></i></span>
            </b-input-group-prepend>
            <b-form-input v-model="$v.input.currentPassword.$model" type="password" id="currentPassword" placeholder="Current Password" @change="clearErrors()"></b-form-input>
          </b-input-group>
          <div class="invalid-feedback d-block" v-if="submitted && $v.input.currentPassword.$invalid">
            <span v-if="!$v.input.currentPassword.required">Current password is required</span>
            <span v-else-if="!$v.input.currentPassword.invalidCurrentPassword">Current password is incorrect</span>
          </div>
        </b-form-group>

        <b-form-group label-for="newPassword">
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text"><i class="icon icon-lock"></i></span>
            </b-input-group-prepend>
            <b-form-input v-model="$v.input.newPassword.$model" type="password" id="newPassword" placeholder="New Password" @change="clearErrors()"></b-form-input>
          </b-input-group>
          <div class="invalid-feedback d-block" v-if="submitted && $v.input.newPassword.$invalid">
            <span v-if="!$v.input.newPassword.required">New password is required</span>
            <span v-else-if="!$v.input.newPassword.invalidNewPassword">New password not valid</span>
            <span v-else-if="!$v.input.newPassword.sameAsCurrentPassword">New password cannot match current password</span>
          </div>
        </b-form-group>

        <b-form-group label-for="passwordConfirm">
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text"><i class="icon icon-lock"></i></span>
            </b-input-group-prepend>
            <b-form-input v-model="$v.input.passwordConfirm.$model" type="password" id="passwordConfirm" placeholder="Retype New Password" @change="clearErrors()"></b-form-input>
          </b-input-group>
          <div class="invalid-feedback d-block" v-if="submitted && $v.input.passwordConfirm.$invalid">
            <span v-if="!$v.input.passwordConfirm.required">Confirm password is required</span>
            <span v-else-if="!$v.input.passwordConfirm.notSameAsNewPassword">Must match new password</span>
          </div>
        </b-form-group>

        <b-button type="submit" variant="primary">Reset Password</b-button>
      </b-form>
    </div>
  </transition>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import { required, not, sameAs } from 'vuelidate/lib/validators'

  export default {
    name: 'changePassword',
    mixins: [FormMixin],
    data() {
      return {
        input: {
          currentPassword: '',
          newPassword: '',
          passwordConfirm: ''
        },
        currentPasswordExpired: this.$root.authStatus.authTypeRequired === 'UPDATE_PASSWORD'
      };
    },
    validations: {
      input: {
        currentPassword: {
          required,
          invalidCurrentPassword() {
            return !this.fieldError('currentPassword');
          }
        },
        newPassword: {
          required,
          invalidNewPassword(newPassword) {
            return this.$root.validation.password.regex.test(newPassword);
          },
          sameAsCurrentPassword: not(sameAs(function() { return this.input.currentPassword }))
        },
        passwordConfirm: {
          required,
          notSameAsNewPassword: sameAs(function() { return this.input.newPassword })
        }
      }
    },
    computed: {
      conditionalPageStyles: function() {
        return (this.currentPasswordExpired) ? "expired-password-page" : "";
      }
    },
    methods: {
      changePassword() {
        this.submit('post', ApiProperties.api.updatePassword, {}, this.input,(response) => {
            this.$root.updateAuthStatus(response.data);
            this.$router.push({name: this.currentPasswordExpired ? 'home' : 'employeeProfile'});
          });
      }
    }
  }
</script>
<style lang="scss">
  @import "../../node_modules/bootstrap/scss/functions";
  @import "../../node_modules/bootstrap/scss/variables";
  @import "../../node_modules/bootstrap/scss/mixins";
  @import '../scss/auth-container';


  .expired-password-page {
    margin:2em auto;
    max-width: 380px;

    @include media-breakpoint-up(lg) {
      width: 50%;
      text-align: center;
    }
  }
</style>
